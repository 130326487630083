import { cancelJob, clearJobs, createDocumentJob, createJob, findScheduledJobs, findUserJobs } from '@/api/jobs.api';
import { type IFindJobsParams, type IJobDTO, finishedJobStates, pendingJobStates } from '@condo/domain';

export interface IJobsStore {
    jobs: IJobDTO[];
    scheduledJobs: any[];
}

export interface ICreateJobParams {
    name?: string;
    params?: Record<any, any>;
    meta?: Record<any, any>;
    opts?: Record<any, any>;
}

function getInitialState(): IJobsStore {
    return {
        jobs: [],
        scheduledJobs: [],
    };
}

const state = getInitialState();

const getters = {
    jobs(state: IJobsStore): IJobDTO[] {
        return state.jobs;
    },
    scheduledJobs(state: IJobsStore): any[] {
        return state.scheduledJobs;
    },
    activeJobs(state: IJobsStore): IJobDTO[] {
        return state.jobs.filter(job => pendingJobStates.includes(job.state));
    },
    invoiceExports(state: IJobsStore): IJobDTO[] {
        return state.jobs.filter(
            job =>
                job.requestData?.meta?.type &&
                ['invoice-export', 'invoice-export-condo-debtor', 'recurrent-payments-transfer'].includes(job.requestData?.meta?.type),
        );
    },
    cashflowPredictions(state: IJobsStore): IJobDTO[] {
        return state.jobs.filter(job => job.requestData?.meta?.type === 'cashflow-prediction');
    },
    finishedJobs(state: IJobsStore): IJobDTO[] {
        return state.jobs.filter(job => finishedJobStates.includes(job.state));
    },
};

const actions = {
    async findScheduledJobs({ commit }) {
        return findScheduledJobs().then(scheduledJobs => {
            commit('setScheduledJobs', scheduledJobs);
            return scheduledJobs;
        });
    },
    async fetchJobs({ commit }, opts: IFindJobsParams) {
        return findUserJobs(opts).then((jobs: IJobDTO[]) => {
            commit('setJobs', jobs);
            return jobs;
        });
    },
    async createJob({ commit }, { name, params, meta, opts }: ICreateJobParams) {
        if (meta?.type === 'document') {
            return createDocumentJob(params).then((job: IJobDTO) => {
                commit('addJob', job);
                return job;
            });
        }
        return createJob(name, params, meta, opts).then((job: IJobDTO) => {
            commit('addJob', job);
            return job;
        });
    },
    async clearJobs({ commit }, { userId }) {
        return clearJobs(userId).then(() => {
            commit('resetJobs', userId);
        });
    },
    async cancelJob({ commit }, jobId: string) {
        return cancelJob(jobId).then(() => {
            commit('updateJob', { jobId, state: 'cancelled' });
        });
    },
};
const mutations = {
    setScheduledJobs(state: IJobsStore, scheduledJobs) {
        state.scheduledJobs = scheduledJobs;
    },
    setJobs(state: IJobsStore, jobs) {
        state.jobs = jobs;
    },
    addJob(state: IJobsStore, job) {
        state.jobs = state.jobs.concat(job);
    },
    resetJobs(state: IJobsStore, userId) {
        state.jobs = state.jobs.filter(job => job.userId !== userId);
    },
    updateJob(state: IJobsStore, updatableJob: Partial<IJobDTO>) {
        state.jobs = state.jobs.map(job => {
            if (job.jobId === updatableJob.jobId) {
                return { ...job, ...updatableJob };
            }
            return job;
        });
    },
};

export const jobsStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
