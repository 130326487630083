import { router } from '@/router';

import { enumToHuman } from '@/shared/filters';
import { store } from '@/store';
import type { IContactUsersObject, IUsersObject } from '@/store/modules/users.store';
import {
    type CalendarStagesUnsorted,
    CatalogueItemType,
    ConstructionCraft,
    CostTypes,
    ExecutedInteriorQualities,
    ExternalLinkStatus,
    ICalendarEvent,
    type IContactPerson,
    type IEstate,
    type IExternalLinkDTO,
    type IInvestment,
    type IOutputDocument,
    type IPaymentOrder,
    type IRentableUnit,
    type IUser,
    type PaymentNoticeSummary,
    type PresentRelations,
    ProcessType,
    RentableUnitStates,
    RoomType,
    UnitName,
    costTypeTranslations,
    costTypesGrouped,
    stateToHuman,
} from '@condo/domain';
import { ITransition, knownDomains, roundAccurately, safeSum } from '@condo/helpers';
import { CondoToast } from '@condo/ui';
import dayjs from 'dayjs';
import { isNil, kebabCase, maxBy, omit, pick } from 'lodash-es';
import type { DialogApiInjection } from 'naive-ui/lib/dialog/src/DialogProvider';
import { type MaybeRef, type VNodeChild, h as createElement, unref } from 'vue';
import { NavigationFailureType, isNavigationFailure } from 'vue-router';
import { TYPE as ToastType, useToast } from 'vue-toastification';
import type { StateMachineStyle } from './typings';

export { ToastType };

const toast = useToast();

export enum DefectMapMode {
    Pick = 'Pick',
    View = 'View',
}

export interface ConfirmDialogArgs {
    title?: string;
    content?: string | VNodeChild;
    isModal?: boolean;
    isDraggable?: boolean;
    showCloseIcon?: boolean;
    closeOnEscape?: boolean;
    position?: {
        X?: string | number;
        Y?: string | number;
    };
    okButton?: Record<any, any>;
    cancelButton?: Record<any, any>;
    animationSettings?: any;
    cssClass?: string;
    zIndex?: number;
    open?: any;
    close?: any;
}

export const getPersistedRegion = (subKey?: string) => {
    if (!subKey?.length) {
        return;
    }
    const persistedRegions = localStorage.getItem(`__CONDO_${subKey}_REGION__`.toUpperCase());
    return persistedRegions ? JSON.parse(persistedRegions) : undefined;
};

export const setPersistedRegion = (subKey?: string, regionNames?: string[]) => {
    if (!subKey?.length) {
        return;
    }
    if (!regionNames?.length) {
        return localStorage.removeItem(`__CONDO_${subKey}_REGION__`.toUpperCase());
    }
    return localStorage.setItem(`__CONDO_${subKey}_REGION__`.toUpperCase(), JSON.stringify(regionNames));
};

export const userName = (uidOrContactId: string | number | undefined, withEmail = false, withLastName = true, short = false, fallBackName = '') => {
    if (!uidOrContactId) {
        return fallBackName;
    }
    const user = store.getters.users[uidOrContactId] ?? store.getters.usersByContactId[uidOrContactId];

    const displayName =
        (!short && user?.displayName ? user?.displayName : null) ??
        [
            short ? (user?.contact?.firstname ? `${user?.contact?.firstname[0].toUpperCase()}.` : '') : user?.contact?.firstname,
            withLastName ? user?.contact?.lastname : '',
        ]
            .filter(Boolean)
            .join(' ');
    const email = user?.email;
    if (displayName) {
        return withEmail && email ? `${displayName} (${email})` : displayName;
    }
    if (email) {
        return email;
    }
    if (uidOrContactId.toString().includes('@gcp.com')) {
        return 'Condo Platform [automatic]';
    }
    return fallBackName ? fallBackName : !short ? '(unknown)' : '?';
};

// fallback value is undefined by default stripping variables from request get string, but leaving null values when needed to request body
export const trimEmptyParameters = (params, fallback = undefined) =>
    Object.entries(params).reduce((result, [key, value]) => {
        result[key] = value === '' ? fallback : value;

        return result;
    }, {});

export const trimFalsyParameters = <T>(params: T): Partial<T> =>
    Object.entries(params).reduce(
        (acc, [key, value]) => {
            if (!value) {
                return acc;
            }
            return { ...acc, [key]: value };
        },
        {} as Partial<T>,
    );

export const trimNilParameters = params =>
    Object.entries(params).reduce((acc, [key, value]) => {
        if (isNil(value)) {
            return acc;
        }
        return { ...acc, [key]: value };
    }, {});

export const deflattenPaginatedParams = (params: Record<string, any>) => {
    const pagination = pick(params, 'order', 'orderBy', 'size', 'page');
    const query = omit(params, 'order', 'orderBy', 'size', 'page');

    return {
        pagination: trimNilOrEmptyParameters(pagination),
        query: trimNilOrEmptyParameters(query),
    };
};
export const flattenPaginatedParams = (params: Record<string, any>) => {
    return {
        ...params,
        ...(params.query ?? {}),
        ...(params.pagination ?? {}),
    };
};
export const trimNilOrEmptyParameters = params => trimEmptyParameters(trimNilParameters(params));

const createDuplicatedRouteHandler = (func?: () => void) => (err: Error) => {
    // Ignore the vue-router error regarding navigating to the page they are already on, and execute callback
    if (isNavigationFailure(err, NavigationFailureType.duplicated) || err.message?.includes('Avoided redundant navigation to current location')) {
        func?.();
    } else {
        console.error(err);
    }
};

/**
 * Updates the query-params for the current route, then executes a callback once its updated
 *
 * @note This method ignores the vue-router error for redundant navigations, e.g. when  navigating to the same page
 */
export const pushToQuery = (routeName: string, params: object, func?: () => void, replace = false, trimFn = trimNilOrEmptyParameters) => {
    const method = replace ? 'replace' : 'push';
    const trimmedParams = trimFn(params);

    return router[method]({ name: routeName, query: trimmedParams ?? {} })
        .then(() => func?.())
        .catch(createDuplicatedRouteHandler(func));
};

export const validateChildInputs = (component): boolean => {
    const inputs = component.$children.map(c => {
        if (c.$options._componentTag === 'condo-field') {
            return c.$children[0];
        }
        if (c.$options._componentTag === 'n-input') {
            return c;
        }
    });
    let isValid = true;
    inputs.forEach(c => {
        if (!c || !c.checkHtml5Validity) {
            return;
        }
        const eValid = c.checkHtml5Validity();
        if (!eValid && isValid) {
            isValid = false;
        }
    });
    return isValid;
};

export interface IDialogResult {
    result: boolean;
}

export interface IDisplayUser {
    nameEmail: string;
    uid?: string;
}

const DEFAULT_CONFIRM_BUTTON = 'OK';
const DEFAULT_CANCEL_BUTTON = 'Cancel';

export const confirmDialog = async (
    dialog: DialogApiInjection,
    questionOrConfig: string | ConfirmDialogArgs,
    type?: string,
    callback?: () => void,
): Promise<IDialogResult> => {
    const config: Partial<ConfirmDialogArgs> =
        typeof questionOrConfig === 'string'
            ? {
                  title: 'Confirm',
                  content: questionOrConfig,
                  okButton: {
                      text: DEFAULT_CONFIRM_BUTTON,
                  },
                  cancelButton: {
                      text: DEFAULT_CANCEL_BUTTON,
                  },
              }
            : questionOrConfig;

    return new Promise<IDialogResult>(resolve => {
        return dialog.warning({
            title: config.title ?? 'Confirm',
            content: () => {
                if (!config.content) {
                    return '';
                }
                return typeof config.content === 'string'
                    ? createElement({
                          template: `<div>${config.content as string}</div>`,
                      })
                    : config.content;
            },
            positiveText: config.okButton?.text ?? DEFAULT_CONFIRM_BUTTON,
            negativeText: config.cancelButton?.text ?? DEFAULT_CANCEL_BUTTON,
            onPositiveClick: () => {
                if (callback) {
                    callback();
                }
                resolve({ result: true });
            },
            onNegativeClick: () => {
                resolve({ result: false });
            },
        });
    });
};

export const confirmDelete = (dialog: DialogApiInjection, question: string, callback?: () => void) => {
    return confirmDialog(dialog, question, 'is-danger', callback);
};

export const getFormattedState = (state: string | object) => (typeof state === 'object' ? Object.keys(state)[0] : state);

export const showToast = (message: string, type: ToastType = ToastType.INFO, timeout = 5000) => {
    return toast(
        {
            component: CondoToast,
            props: {
                message,
                type,
            },
        },
        { type, timeout },
    );
};

export const getImgUrl = (folder, pic) => `/img/${folder}/${pic}.png`;

export const previewPDFDocument = (doc: IOutputDocument) => {
    return import('@/components/documents/PdfViewer.vue').then(mod => {
        return showModal(mod.default, {
            props: { doc },
            width: 1000,
        });
    });
};

export const showModal = (component, setting: object = {}) => {
    store.commit('generalData/showModal', { component, width: 400, ...setting, trapFocus: false });
    return {
        close: () => store.commit('generalData/hideModal'),
    };
};

export const costTypeForSelect = Object.entries(costTypesGrouped).flatMap(([group, items]) => ({
    group: enumToHuman(group),
    items: items.map(item => ({ label: costTypeTranslations[item], id: item })),
}));

export const costTypeFlat = Object.entries(costTypesGrouped).reduce((acc, pair) => {
    return [...acc, ...pair[1].map(c => ({ val: costTypeTranslations[c], id: c, group: enumToHuman(pair[0]) }))];
}, []);

export const costTypeOptions = Object.entries(costTypesGrouped).reduce((acc, pair) => {
    return [
        ...acc,
        {
            type: 'group',
            label: enumToHuman(pair[0]),
            key: pair[0],
            children: pair[1].map(c => ({ label: costTypeTranslations[c], value: c })),
        },
    ];
}, []);

export const allCostTypes = Object.values(CostTypes).map(id => ({
    label: costTypeTranslations[id],
    id,
}));

export interface IDecorateTransitions extends ITransition {
    icon: string;
    type: string;
}

//todo: this one is not supporting parallel transitions, extend if required
export const decorateTransitions = (style: StateMachineStyle<any>, transitions: ITransition[]): IDecorateTransitions[] =>
    transitions.map(t => {
        const actionStyle = style[t.event] ?? style.default;
        const { icon, type } = actionStyle;
        return { ...t, icon, type };
    });

export function setEventToNoon(val): Date {
    const date = val._date ?? val;

    return dayjs(date).hour(12).minute(0).second(0).utc().toDate();
}

export const dateToUtcWithoutChange = (date?: Date): Date | null => (date ? dayjs(date).utc(true).toDate() : null);

export const convertToMidDay = (val: Date) => dayjs(val).hour(12).toDate();

export const userContact = (val: string): IContactPerson | null => {
    return (store.getters.users[val] as IUser)?.contact ?? null;
};

export const userFromContactId = (contactId: number): IContactPerson | null => (store.getters.usersByContactId[contactId] as IUser)?.contact ?? null;

export const mapUserForDisplay = (user: IUser | IContactPerson): (IUser & IDisplayUser) | (IContactPerson & IDisplayUser) => {
    const displayName = (user as IUser).displayName ?? [(user as IUser).firstName, (user as IUser).lastName].filter(Boolean).join(' ');

    return {
        ...user,
        nameEmail: user.email && displayName ? `${displayName} (${user.email})` : (displayName ?? '(unknown)'),
    };
};

export const withLoading = async <T>(func: () => Promise<T>): Promise<T> => {
    try {
        store.commit('ui/toggleLoading', true);
        return await func();
    } finally {
        store.commit('ui/toggleLoading', false);
    }
};

/*Start of the experiment by not using vue component as grid renderer. Please don't delete*/
export const getRouterLikeLinkForGrid = (path: string, linkText: string): string => `<a href="${path}" class="gridLink" target="_self">${linkText}</a>`;

/*Filters Moved To One File*/
export function getEventStartDateByStage(events: ICalendarEvent[], stage: CalendarStagesUnsorted) {
    const [event] = events.filter(event => event.stage === stage);
    return event?.start;
}

export const fromSellingOrPurchasingInvestmentCase = <TKey extends keyof IInvestment>(e: IEstate, k: TKey): IInvestment[TKey] | undefined =>
    e?.executedSellingInvestmentCase?.[k] ?? e?.executedInvestmentCase?.[k];

export const enumToNSelectOption = (enumObj: object) => Object.entries(enumObj).map(([_key, val]) => ({ label: enumToHuman(val), value: val }));
export const arrayToNSelectOptions = (items: any[], labelField?: string | ((i: any) => string), valueField?: string) =>
    items
        ? items.map(i => {
              if (typeof i === 'string') {
                  return { label: i, value: i };
              }
              const label = typeof labelField === 'function' ? labelField(i) : i[labelField];
              return { ...i, label, value: i[valueField] };
          })
        : [];

export const interiorQualityList = ExecutedInteriorQualities.map(quality => ({
    label: enumToHuman(quality),
    value: quality,
}));

export const craftsList = Object.values(ConstructionCraft).map(craft => ({
    label: enumToHuman(craft),
    value: craft,
}));

export const estateRoomTypes = Object.values(RoomType).map(roomType => ({
    label: enumToHuman(roomType),
    value: roomType,
}));

export const unitsList = Object.values(UnitName).map(el => ({ label: el, value: el }));

export const catalogueItemTypes = Object.values(CatalogueItemType).map(el => ({ label: el, value: el }));

export const calculatePaymentNoticeDelta = (paymentNoticeSummary: PaymentNoticeSummary): number => {
    const sum = roundAccurately(
        paymentNoticeSummary.paymentOrders.reduce((acc, cur) => safeSum(acc, cur.amount), 0),
        2,
    );
    return roundAccurately(safeSum(paymentNoticeSummary.purchasingPrice, -sum), 2);
};

export const getPaymentOrderMaxAmountAllowed = (paymentOrder: IPaymentOrder, paymentNoticeSummary: PaymentNoticeSummary): number => {
    const delta = calculatePaymentNoticeDelta(paymentNoticeSummary);
    return paymentOrder.paymentOrderId ? roundAccurately(safeSum(delta, paymentOrder.amount), 2) : delta;
};

export const openDownloadLink = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadBlob = (data: Uint8Array | string, fileName: string, mimeType: string) => {
    const blob = new Blob([data], {
        type: mimeType,
    });
    const url = URL.createObjectURL(blob);
    openDownloadLink(url, fileName);
};

export function convertObjectToCSV(arr: Record<string, any>[]) {
    if (arr.length === 0) return '';
    const array = [Object.keys(arr[0])].concat(arr as any);
    return array
        .map(row => {
            return Object.values(row)
                .map(value => {
                    return typeof value === 'string' ? JSON.stringify(value) : value;
                })
                .toString();
        })
        .join('\n');
}

export const downloadBlobFromUrl = async (url: string, fileName: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    const blobUrl = URL.createObjectURL(data);

    openDownloadLink(blobUrl, fileName);
};

export const base64ToFormData = (base64: string, fileName: string, mimeType: string): FormData => {
    const base64Data = base64.replace(/^data:image\/\w+;base64,/, '');
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    const formData = new FormData();
    formData.append('file', blob, fileName);

    return formData;
};

export const processUsersList = (users: IUser[]) => {
    const baseUsers = users.filter(u => !!u.email);
    const usersObject = baseUsers.reduce((acc, user) => ({ ...acc, [user.uid]: user }), {} as IUsersObject);

    return {
        usersObject,
        usersByContact: baseUsers
            .filter(u => u.contact?.contactId)
            .reduce((acc, user) => ({ ...acc, [user.contact!.contactId!]: user }), {} as IContactUsersObject),
        companyFunctions: users.filter(u => !!u.contact?.companyFunction).map(u => ({ ...u.contact!.companyFunction, contact: u.contact })),
    };
};

export type RentableUnitWithProcess = IRentableUnit<'created'> & PresentRelations<{ rentableUnitProcess: true }>;

export type FlaggedRentableUnit = RentableUnitWithProcess & { reverted: boolean };

/**
 *
 * @param rentableUnits array of rentableUnits containing it's rentableUnitProcess (mandatory to check if a rentableUnit was reverted or not)
 * @returns a **NEW** array with the same rentableUnits but with property { reverted: boolean } added
 */
export const flagRevertedRentableUnits = (rentableUnits: RentableUnitWithProcess[]): FlaggedRentableUnit[] => {
    return rentableUnits.map(ru => ({ ...ru, reverted: ru.rentableUnitProcess?.processState?.state === RentableUnitStates.Reverted }));
};

/**
 * Returns a function with the enclosed _actionIdPrefix_ that takes an _action_ name and returns a string _actionIdPrefix.action_.
 */
export const elementIdBuilder = (actionIdPrefix?: MaybeRef<string>) => {
    const actionIdPrefixValue = actionIdPrefix ? `${unref(actionIdPrefix)}.` : '';
    return (action: string): string => `${actionIdPrefixValue}${kebabCase(action)}`;
};

export const findLatestActiveLinkOfMfh = (externalLinks: IExternalLinkDTO[]) => {
    if (!externalLinks?.length) {
        return undefined;
    }
    const activeLinks = externalLinks
        .filter(link => link.status === ExternalLinkStatus.ACTIVE)
        .sort((linkA, linkB) => dayjs(linkB.updatedAt).unix() - dayjs(linkA.updatedAt).unix());
    const [lastLink] = activeLinks;
    const lastIS24Link = activeLinks.find(link => link.domain === knownDomains.IS24);
    const lastImmonetLink = activeLinks.find(link => link.domain === knownDomains.IMMONET);
    return lastIS24Link || lastImmonetLink || lastLink;
};

export const pickLastRentContractFromEstate = (estate: IEstate) => maxBy(estate.rentalContracts ?? [], 'updatedAt');

export const rentRollStatusForOneEstate = (data: IEstate) => {
    return stateToHuman(data.renting?.processState?.state as string, ProcessType.Renting);
};

export const convertToCssClass = (term: string) => term.replace(/ /g, '-').toLowerCase();

export function convertToCSV(data: Record<any, any>) {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(','));
    return [header, ...rows].join('\n');
}

export function downloadCSV(csvString: string) {
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
}

export function formatIBAN(iban: string): string {
    if (!iban) return '';
    iban = iban.replace(/\s+/g, '');
    const groups = iban.match(/.{1,4}/g);
    return groups ? groups.join(' ') : '';
}
